// api模块
import config from "@/common/config"
import tool from "@/common/tool"
const urls = {
  openid: "https://server.medflying.com/api/common/wx/openid",//openid
  jssign: 'https://server.medflying.com/api/common/wx/jssign',//获取js SDK
  oauth: '/wx/set_cookie_openid',//,//验证
  agreementShare: `${config.host}/#/agreement`,//协议分享
  uploads: {
    uploadBase64: "https://files.medflying.com/file_system/filesbase64",
    uploadFile: "https://files.medflying.com/file_system/files",
  },
  user: {
    code: "/api/sendsms",
    login: "/api/login"  // meetInfo
  },
  admin: {
    code: "/show/sendsms",
    login: "/admin/login"  //登录     
  },
  doctor: {
    info: "/doctor/info", // 保存和获取专家信息
    delete: "/doctor/remove", // 删除专家
    match: "/doctor/match",// 智能匹配
    sign: "/doctor/meet_sign", // 签名
    agreement: "/doctor/meet_info", // 签名
    haveCourseware: "/doctor/have_courseware",
    courseware_list: "/list/class", // 课件列表
    doctor_material: "/doctor/material", // 材料信息
  },
  cancer: {
    list: "/cancer", // 癌种列表
  },
  live: {
    get_sign_history: "/live/sign_history", // 签到历史
    clear_sign_history: "/live/sign_info", // 清除签到历史
    list: '/user/meet',// 我的会议
    all: "/forum/get_post",//帖子列表
    create: "/forum/add_post",//创建帖子
    update: "/forum/patch_post",//修改帖子
    detail: "/forum/post_detail",//帖子详情
    detailAll: "/forum/post_detail_all",//所有人都可以看的那种
    mode: "/forum/free_post",//更改模式
    replay: "/forum/reply_post",//回复帖子
    agree: "/forum/post_invite_agree",//同意参会
    invite: "/forum/post_invite",//邀请参会
    inviteOther: "/forum/patch_invite",//修改邀请专家
    updateDoctor: "/forum/invite_compulsion",//修改会议专家
    updateMeetMode: "/forum/match_post",// 修改会议模式
    chatList: "/forum/room/chat", //聊天记录列表
    liveList: "/forum/live", //聊天记录列表
    join: "/forum/join_post", // 加入会议
    playList: "/forum/live", // 直播列表
    sign: "/live/sign", // 直播签到
    sign_info: "/live/sign_info", // 签到信息
    submit: "/forum/finish_group", // 提交审核
    refuse: "/forum/reject_post", // 拒绝会议
    controlbook: '/meet/controlbook',//操作手册
    sendMeetTimeChat: '/msg_send_meet_chat',// 管理员 会议提醒 时长
    sendMeetUserChat: '/msg_send_meet_chat_user',// 管理员 人员不足提醒 时长
    signUser: "/forum/live_user", // 签到人数
    sendCommon: "/msg_send_common", // 通用模板
    meetTime: config.host + "/back/forum/meet_time", // 修改会议时间
    signTime: "/live/sign_info", // 签到次数
    exit: "/forum/exit_group", // 退出会议
    agreeExit: "/forum/post_invite_on_hold",//  统一退出
    refuseExit: "/forum/post_invite_on_hold",// 邀请退出
    changeInvite: "/forum/post_invite_five",// 改变邀请
    customerInfo: "/forum/customer_info",// 客服
    matchSignDoctor: "/live/match_sign_doctor",// 签到匹配  
    meetNumWarning: "/forum/meet_num_warning",// 会议饱和度
    executeLog: "/meet/execute_log",// 会议执行记录
    meetList: "/meet/list",//协作人会议列表  会议详情
    doCreate: config.host + '/back//meet/create_meet',// 创建会议post
    doEdit: config.host + '/back/meet/info',// 修改会议patch
    doCancer: config.host + '/back/cancer',// 癌种get

  },
  all: {
    hospital: "/user/hospital",
    doctor_hospital_list: "/hospital/list"//医生信息页面筛选用到的医院列表
  },
  meet: {
    patch_link: '/link/info',//环节标题修改
    patch_process: '/process/info',//议程标题修改
    patch_module: '/module/info',//模块标题修改
    patch_team: '/team/info',//团队修改
    meet_detail: '/meet/detail',//会议详情
    list: '/doctor/meet',// 会议列表
    info: "/meet/info",// 保存，和修改会议信息
    help: '/help/info',// 增加，删除，修改协作人
    version: '/version/info', // 版本信息,
    rollback: '/version/rollback', //回滚版本
    submit: "/meet/commit",// 提交会议
    report: "/meet/paper", // 报告
    projectSummary: '/data/show_header', // 汇总
    projectDetail: '/data/show_check', // 汇总
    material_show_file: '/meet/show_file',//物料
    phase: "/meet/phase", // 阶段增删
    link_times: "/meet/links", // 没有会议时间时虚构的环节时间
    meetDoctors: '/meet/doctors', // 请求专家劳务数据
    auditMoney: config.host + "/back/meet/audit_money", //协办人审核金额
    exchangeTeamDoctor: config.host + '/back/exchange/team/doctor', //交换团队医生顺序
    exchangeLinkDoctor: config.host + '/back/exchange/link/doctor', //交换专家顺序 
  },
  team: {
    team_list: "/team/list",//团队列表
    team_remove: "/team/remove",//删除团队
    team_patch: "/team/info",//团队修改
  }
}

let host = tool.isLocal() ? config.localhost : config.host;
for (let key in urls) {
  if (typeof urls[key] == "object") {
    for (let i in urls[key]) {
      if (urls[key][i].includes("http")) continue;
      urls[key][i] = `${host}${config.urlPrefix}${urls[key][i]}`;
    }
    continue
  }
  if (urls[key].includes("http")) continue;
  urls[key] = `${host}${config.urlPrefix}${urls[key]}`;
}
export default urls;