import { createRouter, createWebHashHistory, } from "vue-router"
import weixin from "@/common/weixin"
import tool from "@/common/tool"
import config from "@/common/config"
import { adminRouterBefore, collaboratorRouteBefore, liveRouterBefore, commonRouterBefore, salesRouteBefore } from './routerBefore'
const commonRoutes = [
  {
    path: "/sign",
    meta: { title: "签到" },
    name: "sign",
    component: () => import(/* webpackChunkName: "sign", webpackPrefetch: true */ "@/components/page/user/signIn.vue")
  },
  {
    path: "/endLive",
    meta: { title: "直播结束提醒" },
    name: "endLive",
    component: () => import(/* webpackChunkName: "endLive", webpackPrefetch: true */ "@/components/page/user/endLive.vue")
  }
]

const liveRoutes = [
  {
    path: "/live",
    beforeEnter: liveRouterBefore,
    name: "live",
    component: () => import(/* webpackChunkName: "live", webpackPrefetch: true */ "@/components/page/live/live.vue")
  },
]

const ignoreRoutes = [
  {
    path: "/login",
    meta: { title: "负责人登录" },
    name: "login",
    component: () => import(/* webpackChunkName: "login", webpackPrefetch: true */ "@/components/page/user/login.vue")
  },
  {
    path: "/collaborator",
    meta: { title: "志愿者登录" },
    name: "collaborator",
    component: () => import(/* webpackChunkName: "collaborator", webpackPrefetch: true */ "@/components/page/user/collaborator.vue")
  },
  {
    path: "/privacyAgreement",
    meta: { title: "隐私协议" },
    name: "privacyAgreement",
    component: () => import(/* webpackChunkName: "privacyAgreement", webpackPrefetch: true */ "@/components/page/agreement/privacyAgreement.vue")
  },
  {
    path: "/signPrivacyAgreement",
    meta: { title: "隐私协议" },
    name: "signPrivacyAgreement",
    component: () => import(/* webpackChunkName: "signPrivacyAgreement", webpackPrefetch: true */ "@/components/page/agreement/signPrivacyAgreement.vue")
  },
  {
    path: "/page404",
    name: "page404",
    component: () => import(/* webpackChunkName: "page404", webpackPrefetch: true */ "@/components/common/page404.vue")
  },
  {
    path: "/jump",
    name: "jump",
    component: () => import(/* webpackChunkName: "jump", webpackPrefetch: true */ "@/components/main/jump.vue")
  },
  {
    path: "/sales/login",
    name: "salesLogin",
    component: () => import(/* webpackChunkName: "salesLogin", webpackPrefetch: true */ "@/components/page/user/loginSales.vue")
  },
  {
    path: "/doctor",
    meta: { title: "" },
    name: "doctor",
    // beforeEnter: salesRouteBefore,
    component: () => import(/* webpackChunkName: "doctor", webpackPrefetch: true */ "@/components/page/doctor/index.vue")
  },
  {
    path: "/coursewares",
    meta: { title: "" },
    name: "coursewares",
    component: () => import(/* webpackChunkName: "coursewares", webpackPrefetch: true */ "@/components/page/coursewares/coursewares.vue")
  },
  {
    path: "/principalMeetList",
    name: "principalMeetList",
    beforeEnter: adminRouterBefore,
    component: () => import(/* webpackChunkName: "principalMeetList", webpackPrefetch: true */ "@/components/page/principalMeet/principalMeetList.vue")
  },
  {
    path: "/createMeet",
    name: "createMeet",
    beforeEnter: adminRouterBefore,
    component: () => import(/* webpackChunkName: "createMeet", webpackPrefetch: true */ "@/components/page/principalMeet/createMeet.vue")
  },
  {
    path: "/laborCostConfirm",
    name: "laborCostConfirm",
    beforeEnter: adminRouterBefore,
    component: () => import(/* webpackChunkName: "laborCostConfirm", webpackPrefetch: true */ "@/components/page/principalMeet/laborCostConfirm.vue")
  },
  {
    path: "/meetinfo",
    name: "meetinfo",
    beforeEnter: salesRouteBefore,
    component: () => import(/* webpackChunkName: "meetinfo", webpackPrefetch: true */ "@/components/page/meet/meetinfo.vue")
  },
  {
    path: "/labor",
    meta: { title: "劳务信息完善" },
    name: "labor",
    beforeEnter: collaboratorRouteBefore,
    component: () => import(/* webpackChunkName: "labor", webpackPrefetch: true */ "@/components/page/doctor/labor.vue")
  },
  {
    path: "/myMeet",
    name: "myMeet",
    beforeEnter: collaboratorRouteBefore,
    component: () => import(/* webpackChunkName: "myMeet", webpackPrefetch: true */ "@/components/page/myMeet/index.vue")
  },
  {
    path: "/agreement",
    meta: { title: "劳务协议签署" },
    name: "agreement",
    component: () => import(/* webpackChunkName: "agreement", webpackPrefetch: true */ "@/components/page/agreement/index.vue")
  },
  // {
  //   path: "/agreementShare",
  //   meta: { title: "劳务协议签署" },
  //   name: "agreementShare",
  //   component: () => import(/* webpackChunkName: "agreementShare", webpackPrefetch: true */ "@/components/page/agreement/index.vue")
  // }
]
const routes = [].concat(commonRoutes, liveRoutes, ignoreRoutes);
// const routes =  []
const router = createRouter({
  history: createWebHashHistory(),
  routes
});

const ignorePages = ignoreRoutes.map(item => item.name) // 不需要验证的页面
router.beforeEach((to, from, next) => {
  tool.saveVersion()
  if (ignorePages.includes(to.name)) { next(); return; }
  // weixin.init()
  document.title = config.title
  // if (to.meta.title !== undefined) {
  //   document.title = !to.meta.title ? config.title : `${config.title}-${to.meta.title}`
  // }

  tool.isLocal() ? development(to, from, next) : produce(to, from, next);
})
async function development(to, from, next) {
  tool.setCookie('openid', 'o2N2L557kfqbx_41LCHlC3ekbXN8')
  inSystem(to, from, next)
}
async function produce(to, from, next) {
  weixin.init()
  let openid = tool.getCookie("openid") || "";
  if (!openid) {
    next({ name: "jump", path: "/jump", query: { path: to.path, ...to.query } })
    return;
  }
  inSystem(to, from, next)
}
async function inSystem(to, from, next) {
  next()
}
export default router;
