//  微信
import config from '@/common/config'
import axios from '@/common/axios'
import urls from '@/common/urls'
import store from '@/store'
import wx from 'weixin-js-sdk'
let isReady = false;
const initWx = () => {
    if (store.state.common.app_id) return;
    // const url = `${urls.jssign}?url=${encodeURIComponent(window.location.href.split('#')[0])}`;
    const url = `${urls.jssign}?url=${encodeURIComponent(window.location.href.split('#')[0])}&id=${config.globalProject}`;
    axios.get(url).then(res => {
        const data = res.data;
        store.dispatch('setItem', { app_id: data.app_id })
        wx.config({
            debug: false,
            appId: data.app_id,
            timestamp: data.timestamp,
            nonceStr: data.noncestr,
            signature: data.signature,
            jsApiList: config.wxinterface
        })

    }).catch(err => {
        console.log("微信SDK校验失败", err)
    })
}
wx.ready(() => {
    // initShare();
    // initMenu();
    isReady = true;
    if (tempShareData) {
        // shareMessage(tempShareData)
        sharePageMessage(tempShareData)
    }
})

let tempShareData = null

const shareMessage = (info) => {
    let url = info.url || window.location.href;
    let logo = info.logo||config.logo;
    console.log(info);
    wx.updateAppMessageShareData({
        title: info.title, // 分享标题
        desc: info.description, // 分享描述
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: logo, // 分享图标
        success: function () {
            // shareAddscrore()
            // 设置成功
        }
    })
    wx.updateTimelineShareData({
        title: info.title, // 分享标题
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: logo, // 分享图标
        success: function () {
            // shareAddscrore()
            // 设置成功
        }
    })

}
const sharePageMessage = (data) => {
    // tempShareData = data;
    if (!isReady) return;
    let title = data.title || '每日头条'
    let desc = data.desc || ""
    let url = data.url || window.location.href
    let logo = data.logo || ''
    console.log(data)
    wx.updateAppMessageShareData({
        title,// 分享标题
        desc,// 分享描述
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        imgUrl: logo, // 分享图标
        success: function () {
            if (data.callback) {
                data.callback()
            }
            // tips.success({ text: "分享成功" });
            // 用户确认分享后执行的回调函数
            // alert('分享成功');
        },
        cancel: function () {
            // 用户取消分享后执行的回调函数
            // tips.success({ text: "分享失败" });
            // alert('分享失败');
        },
        error: function () {
            // alert('分享失败');
            // tips.success({ text: "分享失败" });
        }
    });
    wx.updateTimelineShareData({
        title: desc.replace('\n', ''), // 分享标题
        link: url, // 分享链接，该链接域名或路径必须与当前页面对应的公众号JS安全域名一致
        // imgUrl:require('@/assets/images/logo.png'),
        imgUrl: logo, // 分享图标
        success: function () {
            // 设置成功
        }
    })

}
const initMenu = () => {
    wx.hideAllNonBaseMenuItem();
    wx.showMenuItems({
        menuList: [
            'menuItem:share:appMessage',
            'menuItem:share:timeline',
            'menuItem:favorite',
        ] // 要显示的菜单项，所有menu项见附录3
    });
}
export default {
    init: initWx,
    shareMessage,
    sharePageMessage,
    initMenu,
    wx,
}
