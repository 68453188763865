
import tools from "@/common/tool"
import config from "@/common/config"
import urls from "@/common/urls"
import axios from "@/common/axios"
import store from "@/store"
export const adminRouterBefore = (to, from, next) => {
  console.log(store.state.common.adminInfo)
  if (store.state.common.adminInfo.phone) {
    next()
  } else {
    let data = tools.getStorage("sales-login-data", sessionStorage) || {};
    if (data.phone && data.code) {
      axios.post(urls.admin.login, data, {}, false).then(res => {
        tools.setStorage("sales-login-data", data, sessionStorage) || {};
        store.dispatch("setItem", { salesInfo: res.data })
        next()
      }).catch(() => {
        next('/login')
      })
      return;
    }
    next('/login')
  }
}
export const collaboratorRouteBefore = (to, from, next) => {
  if (store.state.common.salesInfo.phone) {
    next()
  } else {
    let data = tools.getStorage("sales-login-data", localStorage) || {};
    if (data.phone && data.code) {
      axios.post(urls.user.login, data, {}, false).then(res => {
        tools.setStorage("sales-login-data", data, localStorage) || {};
        store.dispatch("setItem", { salesInfo: res.data })
        next()
      }).catch(() => {
        next('/collaborator')
      })
      return;
    }
    next('/collaborator')
  }
}

const jumpHome = (to, from, next) => {
  if (to.name == "home" && !tools.getStorage("jumpHome") && store.state.common.userInfo.invite_count > 0) {
    tools.setStorage("jumpHome", 1)
    next("/myMeetList")
  } else {
    next();
  }
}

export const commonRouterBefore = (to, from, next) => {
  next()
}
export const salesRouteBefore = (to, from, next) => {
  if (store.state.common.salesInfo.name) {
    next()
  } else {
    let data = tools.getStorage("sales-login-data", sessionStorage) || {};
    if (data.phone && data.code) {
      axios.post(urls.user.login, data, {}, false).then(res => {
        tools.setStorage("sales-login-data", data, sessionStorage)
        store.dispatch('setItem', { salesInfo: res.data })
        next()
      }).catch(() => {
        tools.setStorage("sales-login-data", {}, sessionStorage)
        next()
      })
      return;
    }
    next()
  }
}
export const liveRouterBefore = async (to, from, next) => {
  let signInfo = store.state.common.signInfo[to.query.id] || {};
  //有type参数表示是飞检链接
  if (to.query.type) {
    let url = tools.getURL(urls.live.sign_info, { id: to.query.id, device_code: encodeURI(tools.getStorage("device-code", localStorage) || "") })
    let response = await axios.get(url)
    // 直播结束 弹提示 直播已结束，感谢观看
    if (response.data.tx_meet_status == 20) {
      next({
        path: `/endLive`
      })
    }
    // query.type == 1为飞检链接，其他为内部链接,inspection_switch为1表示该场会被飞检，反正不是
    if (to.query.type == 1 && response.data.inspection_switch != 1) {
      next({
        path: `/endLive`,
        query: {
          isFlyCheckUrl: 1
        }
      })
    }
    next()
    return;
  }
  // if (to.query.sign) {
  //   next()
  //   return;
  // }
  if (to.query.sign && signInfo.openid) {
    if (to.name == "live") next()
    else next({
      path: `/live`,
      query: to.query
    })
    return;
  }
  // if(to.name == 'sign'){
  //   next();
  //   return;
  // }
  let url = tools.getURL(urls.live.sign_info, { id: to.query.id, device_code: encodeURI(tools.getStorage("device-code", localStorage) || "") })
  let response = await axios.get(url)
  // 直播结束 弹提示 直播已结束，感谢观看
  if (response.data.tx_meet_status == 20) {
    next({
      path: `/endLive`
    })
  }
  if (to.query.sign && response.data.status == 1) {
    signInfo[to.query.id] = response.data
    store.dispatch("setItem", { signInfo })
    tools.setStorage("device-code", response.data.device_code, localStorage)
    next({
      path: `/live`,
      query: to.query
    })
  } else {
    if (to.name == 'sign') next();
    else next({
      path: `/sign`,
      query: { id: to.query.id }
    })
  }
}